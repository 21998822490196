<template>
  <div id="betting-pool-layout">
    <v-app-bar
      app
      clipped-right
      color="green darken-4"
      dark>
      <portal-target name="navbar" />
      <v-spacer />
      <portal-target name="navbar-right" />
      <!--      <v-btn-->
      <!--        icon-->
      <!--        @click="openSettings">-->
      <!--        <font-awesome-icon icon="cog" />-->
      <!--      </v-btn>-->
      <v-menu offset-y>
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            icon
            v-on="on">
            <font-awesome-icon icon="globe" />
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group
            v-model="lang"
            color="primary"
            mandatory
            @change="changeLanguage">
            <v-list-item
              v-for="lng in Object.keys(languages)"
              :key="lng"
              :value="languages[lng]">
              <v-list-item-title>{{ $t("enumerations.language." + lng) }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-btn
        icon
        @click="logout">
        <font-awesome-icon icon="sign-out-alt" />
      </v-btn>
    </v-app-bar>
    <v-slide-x-transition
      id="slide-wrapper"
      mode="out-in">
      <router-view
        ref="currentPage" />
    </v-slide-x-transition>
  </div>
</template>

<script>
import { LOGOUT_USER } from "@/store/actions.js";
import { RENEW_TOKEN, SET_LANGUAGE } from "@/store/mutations.js";
import HttpStatus from "http-status";
import { attachResponseInterceptor, detachResponseInterceptor } from "@/api/httpClient.js";
import { changeLanguage } from "@/api/user.js";
import Language from "@/imported/Language.json";
import BaseLayout from "@/components/layout/BaseLayout.js";
import jwtDecode from "jwt-decode";
import moment from "moment-timezone";
import CLOCK_MODULE, { MODULE_NAME as CLOCK_MODULE_NAME } from "@/store/modules/clock";
import { SET_TIME, SET_TIME_ZONE } from "@/store/modules/clock/mutations.js";
import { START_CLOCK, STOP_CLOCK } from "@/store/modules/clock/actions.js";
import { mapState } from "vuex";

export default {
  name: "BettingPoolLayout",
  extends: BaseLayout,
  data() {
    return {
      lang: this.$store.state.lang,
      languages: Language,
      linkScreenDialogOpen: false,
      axiosInterceptorIdentifier: null,
    };
  },
  computed: {
    ...mapState({
      token: state => state.token,
      sessionExpiration: state => state.session.exp,
    }),
  },
  created() {
    const {
        serverTime,
        timeZone,
      } = jwtDecode(this.token).data,
      parsedDate = moment(serverTime).
        tz(timeZone);

    this.$store.registerModule(CLOCK_MODULE_NAME, CLOCK_MODULE);
    this.$store.commit(`${CLOCK_MODULE_NAME}/${SET_TIME_ZONE}`, timeZone);
    this.$store.dispatch(`${CLOCK_MODULE_NAME}/${START_CLOCK}`, parsedDate);
    this.$watch(`$store.state.${CLOCK_MODULE_NAME}.time`, currentTime => {
      const timeZone = this.$store.state[CLOCK_MODULE_NAME].timeZone,
       expirationTime = moment(this.sessionExpiration * 1000).tz(timeZone);

      if (currentTime.isAfter(expirationTime)) {
        this.logout();
      }
    });

    if (!this.$cable.connected) {
      this.connectWebsocket({ token: `Bearer ${this.$store.state.token}` });
    }
    this.axiosInterceptorIdentifier = attachResponseInterceptor(
      (response, token) => {
        if (token) {
          const decodedToken = jwtDecode(token),
            { serverTime, timeZone, } = decodedToken.data;
          this.$store.commit(RENEW_TOKEN, { token, exp: decodedToken.exp });
          this.$store.commit(`${CLOCK_MODULE_NAME}/${SET_TIME}`, moment(serverTime).
            tz(timeZone));
          this.$store.commit(`${CLOCK_MODULE_NAME}/${SET_TIME_ZONE}`, timeZone);
        }
      },
      error => {
        if (error.response) {
          const { status } = error.response;

          if (status === HttpStatus.NOT_FOUND) {
            console.warn(
              `API Endpoint with url: '${error.config.url}' not found.`,
            );
          } else if (status === HttpStatus.BAD_REQUEST) {
            console.error(error);
          } else if (status === HttpStatus.UNAUTHORIZED) {
            this.logout();
          } else if (status === HttpStatus.INTERNAL_SERVER_ERROR) {
            console.error(error);
          }
        }
        return Promise.reject(error);
      },
    );
  },
  beforeDestroy() {
    this.disconnectWebsocket();
    detachResponseInterceptor(this.axiosInterceptorIdentifier);
    this.$store.dispatch(`${CLOCK_MODULE_NAME}/${STOP_CLOCK}`);
    this.$store.unregisterModule(CLOCK_MODULE_NAME);
  },
  methods: {
    async changeLanguage() {
      const { $store } = this;
      try {
        await changeLanguage($store.getters.userId, this.lang);
        $store.commit(SET_LANGUAGE, { lang: this.lang });
      } catch (e) {
        console.error(e);
      }
    },
    logout() {
      this.$store.dispatch(LOGOUT_USER).
        then(() => {
          this.$router.push({ name: this.$routes.session.createRoute.name });
        });
    },
  },
};
</script>

<style scoped>

#betting-pool-layout {
  background: url("../../../public/img/backgrounds/wood.jpeg");
  height: 100%;
  overflow: hidden;
}
</style>
