import { checkSession } from "@/api/session.js";
import Routes from "../../router/routes";
import Store from "../../store";
import { LOGOUT_USER } from "@/store/actions.js";

export default {
  async beforeRouteEnter(to, from, next) {
    if (from.path === "/") {
      try {
        await checkSession();

        return next();
      } catch (e) {
        await Store.dispatch(LOGOUT_USER);

        return next({ name: Routes.session.createRoute.name });
      }
    } else {
      return next();
    }
  },
};
